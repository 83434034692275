import React from 'react';
import LEAD_FIELDS from '../../utils/leadFields';
import FilterDropdown from './FilterDropdown';

import './FilterBar.scss';

export default function FilterBar({ filters, setFilters }) {

  const deleteFilter = index => {
    setFilters(draft => {
      draft.splice(index, 1);
    });
  }

  const renderFilter = (filter, index) => (
    <div key={filter.field} className='selected-filter'>
      <FilterDropdown toggle={EditFilterToggle} filters={filters} setFilters={setFilters} index={index}>
        <span className='field'>{ LEAD_FIELDS[filter.field]}</span> <span className='match'>{filter.match}</span> <span className='search'>{filter.value}</span>
      </FilterDropdown>

      <button className='remove-filter' onClick={() => deleteFilter(index)}>✕</button>
    </div>
  );

  return (
    <div className='__filter-bar'>
      <div className='content'>
        <div className='filters'>
          { filters.map((filter, index) => renderFilter(filter, index)) }
          <FilterDropdown toggle={PlusToggle} filters={filters} setFilters={setFilters}>+</FilterDropdown>
        </div>

        <button className='clear-filter' onClick={() => setFilters([])}>Clear Filter</button>
      </div>
    </div>
  )
}

const EditFilterToggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props} className='edit-filter'>
    { children }
  </button>
));

const PlusToggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props} className='add-filter'>
    { children }
  </button>
));

