import { useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../utils/api";

const getCategory = async ({ queryKey }) => {
  const [,, categoryId] = queryKey;
  
  if (!categoryId) {
    console.warn('useCategory: missing categoryId');
    return;
  }

  return api.get(`/categories/${categoryId}/`).then(res => res.data);
}

export default function useCategory({ businessId, categoryId, ...props }) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey    : ['categories', businessId, categoryId],
    queryFn     : getCategory,
    initialData : () => queryClient.getQueryData(['categories', businessId])?.find(category => category.id === categoryId),
    ...props
  });
}
