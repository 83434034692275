import AuthForm from "./AuthForm";

import './LoginSignup.scss';

export default function Login() {
  return (
    <section className='__login-signup'>
      <h1>Precious Leads</h1>
      <div className="form-language">
        <AuthForm type='login' />
      </div>
    </section>
  )
}
