import { useState } from 'react';
import { api, handleNetworkError } from '../../utils/api';
import { Trans as T, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import PasswordInput from '../../common/PasswordInput/PasswordInput';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ReactComponent as CheckMarkIcon } from "../../assets/icons_svg/check-mark-outline.svg";

import './ResetPassword.scss';

export default function ResetPassword(props) {

  const [searchParams] = useSearchParams();

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const token = searchParams.get('token')?.trim() || null;
  const uid = searchParams.get('uidb64')?.trim() || null;

  const { t } = useTranslation();

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);
    
    setValidated(false);
    setSuccessMessage(false);
    setErrors(false);

    try {
      token ? await resetPassword() : await sendEmail();
    }
    catch (e) {
      const { networkErrors } = handleNetworkError({ e, setErrors, setValidated });

      // ignore token or uid errors and set a non-field error:
      if (networkErrors?.token || networkErrors?.uid) {
        setErrors({ non_field_errors: ['Your reset password link is invalid or it might have expired. Try resetting it again or contact us.'] });
        setValidated(false);
      }

      // for reset password, don't set any fields with ✔️ as the errors mostly come on new_password2 field:
      if (token) {
        setValidated(false);
      }
    }
    finally {
      setLoading(false);
    }
  }

  const resetPassword = async () => {
    const { data } = await api.post('/reset-password/confirm/', { 
      uid,
      token,
      new_password1: password,
      new_password2: passwordConfirm,
    });

    setSuccessMessage(data.detail);
  }

  const sendEmail = async () => {
    const { data } = await api.post('/reset-password/', { email });
    setSuccessMessage(data.detail);
  }

  const renderField = () => {
    if (token) {
      const passwordFeedback = errors?.new_password1?.map(error => (
        <Form.Control.Feedback key={error} type="invalid">
          <T>{ error }</T>
        </Form.Control.Feedback>
      ));

      const passwordFeedbackConfirm = errors?.new_password2?.map(error => (
        <Form.Control.Feedback key={error} type="invalid">
          <T>{ error }</T>
        </Form.Control.Feedback>
      ));

      // errors such as "passwords don't match" or "password is too common" come as errors of new_password2 (not new_password1):
      const showInvalid = errors?.new_password2?.length > 0 && errors?.new_password2?.[0] !== 'This field may not be blank.';

      return (
        <>
          <PasswordInput className="my-2" placeholder={t('Password')} required value={password} onChange={e => setPassword(e.target.value)} feedback={passwordFeedback} isValid={validated && !errors?.new_password1} isInvalid={errors?.new_password1 || showInvalid} />
          <PasswordInput className="my-2" placeholder={t('Confirm password')} required value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} feedback={passwordFeedbackConfirm}  isValid={validated && !errors?.new_password2} isInvalid={errors?.new_password2} />
        </>
      );
    }

    else {
      return (
        <>
          {/* <Form.Label>Email Address</Form.Label> */}
          <Form.Control type="email" placeholder={t('Email address')} required value={email} onChange={e => setEmail(e.target.value)} isValid={validated && !errors?.email} isInvalid={errors?.email} />
          { errors?.email?.map(error => <Form.Control.Feedback key={error} type="invalid"><T>{ error }</T></Form.Control.Feedback> )}
        </>
      )
    }
  }

  return (
    <section className='__reset-password'>
      <h1>Precious Leads</h1>
      <Form onSubmit={handleSubmit} noValidate validated={validated && !errors}>
        { !successMessage && <h2>{ token ? <T>Reset Password</T> : <T>Forgot your password?</T> }</h2> }
        { token && !successMessage && <p>Enter your new password:</p> }
        { !successMessage && <Form.Group className="field">{ renderField() }</Form.Group> }

        <div className="action">
          { successMessage && <CheckMarkIcon className='success-icon' /> }
          { !successMessage && (
            <button disabled={loading} type='submit'>
              { loading
              ? <Spinner as="span" animation="border" size="sm" />
              : token ? <T>Continue</T> : <T>Reset Password</T>
              }
            </button>
          )}
          { !successMessage 
          ? <div className="request-error">{ errors?.non_field_errors?.map(error => <div key={error}>{ error }</div>) || '\u00A0' }</div> 
          : <div className="request-success">{ successMessage || '\u00A0' }</div>
          }
        </div>

        { ((token && successMessage) || (!token && !successMessage)) && <p className='create-account'><Link to='/login'><T>Back to Log In</T></Link></p> }
      </Form>
    </section>
  )
}
