import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getCategories = ({ queryKey }) => {
  const [, businessId] = queryKey;
  
  if (!businessId) {
    return;
  }

  return api.get(`/categories/?business_id=${businessId}`).then(res => res.data);
}

export default function useCategories({ businessId, ...props }) {
  return useQuery({ queryKey: ['categories', businessId], queryFn: getCategories, ...props });
}
