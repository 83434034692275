import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { api } from "../utils/api";

const apiUpdate = ({ data, id }) => api.put(`/categories/${id}/`, { ...data }).then(res => res.data);

export default function useMutateCategory() {
  const queryClient = useQueryClient();

  const onUpdate = ({ data: newData, id }) => {
    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        const index = data.findIndex(status => status.id === id);
        draft[index] = {...data[index], ...newData };
      }

      else {
        return { ...data, ...newData };
      }
    }))
  }

  return {
    updateCategory: useMutation({ mutationFn: apiUpdate, onMutate: onUpdate }),
  }
}