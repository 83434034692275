import { createContext } from "react";
import { resetAccessToken } from "./api";
import Cookies from 'js-cookie';

const AppContext = createContext();

const APP_INITIAL_SETTINGS = {
  navigation_collapsed : false,
  leads_mode           : 'list'  // ['kanban', 'list']
}

const initialLocalSettings = () => {
  const storedSettings = localStorage.getItem('pl__local_settings');

  if (storedSettings) {
    try {
      return JSON.parse(storedSettings);
    }
    catch {}
  }

  return APP_INITIAL_SETTINGS;
}

export const APP_INITIAL_STATE = {
  currentUser      : null,
  accessToken      : null,
  loggedIn         : null,
  localSettings    : initialLocalSettings(),
};

export const appReducer = (draft, action) => {
  switch (action.type) {
    case 'accessToken/set':
      const accessToken = action.payload;

      draft.accessToken = accessToken;
      resetAccessToken(accessToken);

      Cookies.set('pl__api_access_token', accessToken, { expires: 365 });
      break;

    case 'loggedIn/set':
      draft.loggedIn = action.payload;
      break;

    case 'currentUser/setId':
      if (!draft.currentUser) {
        draft.currentUser = {};
      }

      draft.currentUser.id = action.payload;
      draft.loggedIn = true;
      break;

    case 'currentUser/set':
      draft.currentUser = action.payload;
      draft.loggedIn = true;
      break;

    case 'currentUser/delete':
      draft.currentUser = null;
      draft.accessToken = null;
      draft.loggedIn = false;

      Cookies.remove('pl__api_access_token');
      resetAccessToken(null);
      break;

    case 'localSettings/edit':
      const { setting, value } = action.payload;
      draft.localSettings[setting] = value;
      localStorage.setItem('pl__local_settings', JSON.stringify(draft.localSettings));
      break;

    default:
      console.warn("Non-existing action type:", action.type);
  }
};

export default AppContext;
