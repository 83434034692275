import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../utils/api";
import { produce } from "immer";

const apiBulkUpdate = ({ data }) => api.put(`/lead-statuses/`, data).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/lead-statuses/${id}/`, { ...data }).then(res => res.data);
const apiCreate = ({ data }) => api.post(`/lead-statuses/`, { ...data }).then(res => res.data);
const apiDelete = id => api.delete(`/lead-statuses/${id}/`).then(res => res.data);

export default function useMutateStatus() {
  const queryClient = useQueryClient();

  const onBulkUpdate = ({ data: newData, categoryId }) => {
    queryClient.setQueryData(['statuses', categoryId], data => produce(data, draft => {
      newData.forEach(newItem => {
        const index = data.findIndex(item => item.id === newItem.id);
        draft[index] = { ...data[index], ...newItem };
      });
    }));
  }

  const onUpdate = ({ data: newData, id }) => {
    queryClient.setQueriesData(['statuses'], data => produce(data, draft => {
      const index = data.findIndex(status => status.id === id);
      
      if (index !== -1) {
        draft[index] = { ...data[index], ...newData };
      }
    }));
  }

  const onCreate = requestData => {
    queryClient.setQueriesData(['statuses'], data => produce(data, draft => {
      draft.unshift(requestData);
    }));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['statuses'], data => produce(data, draft => {
      const index = data.findIndex(status => status.id === id);
      draft.splice(index, 1);
    }));
  }

  return {
    bulkUpdateStatus : useMutation({ mutationFn: apiBulkUpdate, onMutate: onBulkUpdate }),
    updateStatus     : useMutation({ mutationFn: apiUpdate, onMutate: onUpdate }),
    createStatus     : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    deleteStatus     : useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
  }
}
