import { useMutation, useQueryClient } from "@tanstack/react-query"
import { produce } from "immer";
import { api } from "../utils/api";

const apiCreate = ({ data }) => api.post('/user-roles/invitations/create/', { ...data }).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/user-roles/${id}/`, { ...data }).then(res => res.data);

export default function useMutateRole() {
  const queryClient = useQueryClient();

  const onCreate = requestData => {
    queryClient.setQueriesData(['user-roles'], data => produce(data, draft => {
      draft.push(requestData);
    }));
  };

  const onUpdate = ({ data: requestData, id }) => {
    queryClient.setQueriesData(['user-roles'], data => produce(data, draft => {
      const index = data.findIndex(item => item.id === id);

      if (index !== -1) {
        draft[index] = { ...data[index], ...requestData }
      }
    }));
  }

  return {
    createInvitation : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    updateUserRole   : useMutation({ mutationFn: apiUpdate, onMutate: onUpdate }),
  }
}
