import { useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as CheckIcon } from '../../assets/icons_svg/check-mark-outline.svg';
import './LeadDistribution.scss';
import Button from '../../common/Button/Button';
import LeadDistributionModal from './LeadDistributionModal';

export default function LeadDistribution(props) {
  const [selected, setSelected] = useState('unassigned');
  const [showEdit, setShowEdit] = useState(false);

  const toggleDistribution = () => {
    setSelected('distribution');
    setShowEdit(true);
  }

  return (
    <div className="__lead-distribution">
      <h2>Lead Distribution</h2>

      <div className={classNames("option", { selected: selected === 'unassigned' })} role='button' onClick={() => setSelected('unassigned')}>
        { selected === 'unassigned' ? <CheckIcon className='check-icon' /> : <div className='radio-box' /> }
        <div className='label-description'>
          <div className="label">Unassigned</div>
          <div className="description">New incoming leads are not assigned to any user by default. Users will need to manually assign each lead.</div>
        </div>
      </div>

      <div className={classNames("option", { selected: selected === 'distribution' })} role='button' onClick={toggleDistribution}>
      { selected === 'distribution' ? <CheckIcon className='check-icon' /> : <div className='radio-box' /> }
        <div className='label-description'>
          <div className="label">Automatic distribution</div>
          <div className="description">Leads will be automatically assigned based on a distribution percentage.</div>
        </div>
        { selected === 'distribution' && <Button className='edit-distribution' onClick={() => setShowEdit(true)}>Edit Distribution</Button> }
      </div>

      <LeadDistributionModal show={showEdit} onHide={() => setShowEdit(false)} />
    </div>
  );
}
