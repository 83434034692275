import { useContext } from "react";
import AppContext from "../../utils/AppContext";

import LeadsListView from "./LeadsListView";
import LeadsKanban from "./LeadsKanban";

export default function LeadsPage() {
  const [state] = useContext(AppContext);
  const { localSettings } = state;
  const kanbanMode = localSettings.leads_mode === 'kanban';

  return kanbanMode ? <LeadsKanban /> : <LeadsListView />;
}
