import { /*useInfiniteQuery,*/ useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getLeads = async ({ categoryId, ordering, filters }) => {
  const params = new URLSearchParams();

  if (ordering) {
    params.append('ordering', ordering);
  }

  filters?.forEach(filter => {
    params.append(filter.field + (filter.match === 'contains' ? '_contains' : ''), filter.value);
  });

  const res = await api.get(`/leads/?category_id=${categoryId}&${params.toString()}`);
  return res.data;
  // return { data: res.data, _count: res.headers['x-total-count']}
}

export default function useLeads({ business, categoryId, ordering, filters, ...props}) {
  return useQuery({ queryKey: ['leads', categoryId, ordering || 'none', filters || 'none'], queryFn: () => getLeads({ categoryId, ordering, filters }), ...props });
  // return useInfiniteQuery({ queryKey: ['leads'], queryFn: () => getLeads(),  ...props, getNextPageParam: lastPage => console.log('page', lastPage )});
}
