import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

import './Toast.scss';

export default function Toast({ show, children, action, hideClose, onActionClick, onHide }) {

  const ref = useRef();

  return (
    <CSSTransition in={show} timeout={500} nodeRef={ref} classNames='__toast'>
      <div className='__toast' ref={ref}>
        <span className='__toast-text'>{ children }</span>
        { action && <button className='__toast-action' onClick={onActionClick}>{ action }</button> }
        { !hideClose && <button className='__toast-close' onClick={onHide}>✕</button> }
      </div>
    </CSSTransition>
  )
}
