import React, { forwardRef } from "react"
import classNames from "classnames"

// import { Handle, Remove } from "../Item"

import "./Container.scss";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    },
    ref
  ) => {
    const Component = onClick ? "button" : "div"

    return (
      <Component {...props} ref={ref}
        style={{
          ...style,
          "--columns": columns
        }}
        className={classNames('__kanban-column', { unstyled, horizontal, hover, placeholder, scrollable, shadow })}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        <div className='header'>{ label }</div>
        <div className='items'>
          { placeholder ? children : <ul>{ children }</ul> }
        </div>
      </Component>
    )
  }
)
