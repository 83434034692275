import axios from 'axios';
import Cookies from 'js-cookie';

const DEFAULT_NETWORK_MESSAGE = "The request could not be processed. Refresh this page or try again momentarily.";

export let api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${Cookies.get('pl__api_access_token')}` }
});

export function resetAccessToken(token) {
  //  instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  //! it seems there is a bug that even the default axios instance will show the headers!!
  // strofeApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
  api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function authorizationHeader() {
  return {
    headers: {
      Authorization: `Bearer ${Cookies.get('pl__api_access_token')}`
    }
  }
}

export const handleNetworkError = ({ e, setErrors, setValidated, message = DEFAULT_NETWORK_MESSAGE }) => {
  // Server down:
  const status = e?.response?.status;
  const data = e?.response?.data;

  let networkErrors;

  if (e?.code === 'ERR_NETWORK' || status === 500) {
    networkErrors = { non_field_errors: [ message ] };
    setValidated?.(false); // do not show the ✔️ checks on form as valid
  }
  // if it's an object, errors are set by Django:
  else if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
    networkErrors = data;
    setValidated?.(true);
  }
  // another type of error (IE: 404, etc) which return a string or HTML reply:
  else {
    networkErrors = { non_field_errors: [ message ] };
    setValidated?.(false); // do not show the ✔️ checks on form as valid
  }

  setErrors?.(networkErrors);

  return { networkErrors };
}