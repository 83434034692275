// As English is the development locale, any phrase that has the same { [key]: value }
// will be added to this array. On different locales, these must exists in the translations file:

const exactTranslations = [
  // Auth
  'Log In',
  'Sign Up',
  'Email address',
  'Password',
  'Forgot your password?',
  'Language',
  'Please enter a valid email',

  // API Errors
  'A user with this email already exists',
  'Enter a valid email address.',
  'The request could not be processed. Please refresh this page or try again',
  'Unable to log in with provided credentials.',
];

export const getExactTranslations = () => {
  const translations = {};
  exactTranslations.forEach(entry => translations[entry] = entry);

  return translations;
}
