import { useImmer } from 'use-immer';
import ReactSlider from 'react-slider';

import Button from '../../common/Button/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import './LeadDistributionModal.scss';

const MOCK_DIST = [
  {
    id         : 1,
    name       : "Magda Zieba",
    role       : "Organization Admin",
    percentage : 0,
  },
  {
    id         : 2,
    name       : "Yi-wen Sun",
    role       : "Organization Admin",
    percentage : 60,
  },
  {
    id         : 3,
    name       : "Andrés Calderón Hernández Montes de Oca",
    role       : "Administrator",
    percentage : 30,
  },
  {
    id         : 4,
    name       : "Ricky Ku",
    role       : "Category User",
    percentage : 10,
  },

]

export default function LeadDistributionModal({ onHide, onSave, ...props }) {

  const [distribution, setDistribution] = useImmer(MOCK_DIST);
  const [percentageInputs, setPercentageInputs] = useImmer(MOCK_DIST.map(dist => dist.percentage + "%"));

  const onChangeValue = (id, value) => {
    setDistribution(draft => {
      const index = draft.findIndex(user => user.id === id);
      draft[index].percentage = value;

      setPercentageInputs(draft => {
        draft[index] = value + "%";
      });
    });
  }

  const onChangeInput = (index, value) => {
    setPercentageInputs(draft => {
      draft[index] = value;
    });
  }

  const onAfterChangeInput = (index) => {
    const intValue = parseInt(percentageInputs[index].replaceAll('%', ''), 10);
    const newValue = (isNaN(intValue) || intValue < 0 || intValue > 100) ? distribution[index].percentage : intValue;

    const difference = newValue - distribution[index].percentage;

    console.log('difference:', difference)

    setPercentageInputs(draft => {
      draft[index] = newValue + "%";
    });

    if (difference !== 0) {
      setDistribution(draft => {
        const oldValue = draft[index].percentage;
        draft[index].percentage = newValue;

        for (let i = 0; i < distribution.length; ++i) {
          if (index !== i) {
            // set all the other percentages to 0
            let newPercentage = 0;

            // if the old value was 100, meaning all others were 0, it means the difference must be distributed between all others:
            if (oldValue === 100) {
              newPercentage = Math.floor(-difference / (distribution.length - 1));
            }
            else {
              newPercentage = Math.floor(draft[i].percentage - (difference / (100 - oldValue) * draft[i].percentage));
            }

            draft[i].percentage = newPercentage;

            setPercentageInputs(draft => {
              draft[i] = newPercentage + "%";
            });        
          }
        }
      });
    }
  }

  return (
    <Modal className='__lead-distribution-modal __modal' onHide={onHide} centered size='lg' backdrop='static' {...props}>
      <Modal.Header closeButton onHide={onHide}><h2>Incoming Lead Distribution</h2></Modal.Header>
      <Modal.Body>
        <table>
          <thead>
            <tr>
              <th className='user'>User</th>
              <th className='role'>Role</th>
              <th className='distribution'>Distribution</th>
            </tr>
          </thead>
          <tbody>
            { distribution.map((user, index) => (
              <tr>
                <td>{ user.name }</td>
                <td>{ user.role }</td>
                <td className='percentage-input'>
                  <Form.Control type='input' value={percentageInputs[index]} onChange={e => onChangeInput(index, e.target.value)} onBlur={() => onAfterChangeInput(index)}  />
                  <ReactSlider className='percentage-slider' value={user.percentage} onChange={value => onChangeValue(user.id, value)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button className='cancel' onClick={onHide}>Cancel</Button>
        <Button className='confirm' onClick={onSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
