import React, { useEffect } from "react"
import { DateTime } from "luxon";
import classNames from "classnames"

// import { Handle, Remove } from "./components"

import  "./Item.scss";

export const Item = React.memo(
  React.forwardRef(
    (
      {
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        item,
        leads,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return
        }

        document.body.style.cursor = "grabbing"

        return () => {
          document.body.style.cursor = ""
        }
      }, [dragOverlay])

      const lead = leads?.find(lead => lead.id === value);

      const leadDate = DateTime.fromISO(lead.date_added);

      const leadName = lead.full_name || `${lead.first_name} ${lead.last_name}`;
      
      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value
        })
      ) : (
        <li
          className={classNames('__kanban-item-wrapper', { fadeIn, sorting, dragOverlay })}
          style={{
            ...wrapperStyle,

            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),

            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,

            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,

            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,

            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,

            "--index": index,
          }}
          ref={ref}
        >
          <div
            className={classNames('__kanban-item', { dragging, withHandle: handle, dragOverlay, disabled })}
            style={style}
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <div className='content'>
              <div className='name'>{ leadName }</div>
              <div className='email'>{ lead.email }</div>

              <div className='date'>
                {/* Added { leadDate.toLocaleString(leadDate.diffNow().as('years') < -1 ? DateTime.DATE_MED : { month: 'long', day: 'numeric'}) } */}
                Added { leadDate.toLocaleString(DateTime.DATE_MED) }
              </div>
            </div>
          </div>
        </li>
      )
    }
  )
)
