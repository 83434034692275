import { forwardRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import runes from 'runes';
import ChevronIcon from '../../assets/icons/ChevronIcon';

import './BusinessDropdown.scss';
import { Link } from 'react-router-dom';

export default function BusinessDropdown({ organizationId, businesses, selected }) {
  const businessInitials = name => runes.substr(name, 0, 1);

  // TODO -> users who cannot switch between businesses
  if (selected && businesses.length === 1) {
    return (
      <div className="__business-toggle">
        <div className='toggle-overflow'>
          <div className="business-logo"><span>{ businessInitials(selected.name) }</span></div>
          <div className="business-name">{ selected.name }</div>
        </div>  
      </div>
    );
  }

  return selected && (
    <div className="__business-toggle">
      <Dropdown>
        <Dropdown.Toggle as={BusinessToggle}>
          <div className="toggle-overflow">
            <div className="business-logo"><span>{ businessInitials(selected.name) }</span></div>
            <div className="business-name">{ selected.name }</div>
            <ChevronIcon className='chevron' />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className='transition-container'>
            { businesses.filter(business => business.id !== selected.id).map(business => (
              <Dropdown.Item key={business.id} as={Link} to={`/?organization_id=${organizationId}&business_id=${business.id}`}>
                <div className="business-logo" style={{ backgroundColor: 'orange' }}><span>{ businessInitials(business.name) }</span></div>
                <div className="business-name">{ business.name }</div>
              </Dropdown.Item>
            ))}
            {/* <Dropdown.Divider /> */}
            {/* <Dropdown.Item className='create-new'>
              Create new business
            </Dropdown.Item> */}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

const BusinessToggle = forwardRef(({ children, onClick, active, ...props }, ref) => (
  <div role='button' onClick={onClick} ref={ref} {...props}>
    { children }
  </div>
));
