const TRANSLATIONS_ES = {
  // Auth
  'Log In'                     : "Iniciar sesión",
  'Email address'              : "Correo electrónico",
  'Password'                   : "Contraseña",
  'Forgot your password?'      : "Olvidaste tu contraseña?",
  'log-in-action'              : "Ingresar",
  'Sign Up'                    : "Registro",
  'sign-up-action'             : "Continuar",
  'have-account-login'         : "¿Ya tienes una cuenta? <1>Ingresa aquí</1>",
  'create-free-account'        : "¿No tienes una cuenta? <1>Crea tu cuenta gratuita</1>",
  'Language'                   : "Idioma",
  'Please enter a valid email' : "Ingresa un correo electrónico válido",
  'password-too-short'         : "La contraseña debe tener un mínimo de 6 caracteres",
  
  // API Errors
  'A user with this email already exists'                                     : "Ya existe un usuario asociado a este correo",
  'Enter a valid email address.'                                              : "Ingresa un correo electrónico válido.",
  'The request could not be processed. Please refresh this page or try again' : "No se pudo completar esta operación. Refresca esta página e intenta de nuevo",
  'Unable to log in with provided credentials.'                               : "Tu correo o contraseña son incorrectos."
}

export default TRANSLATIONS_ES;
