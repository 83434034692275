import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
import AppContext from "../../utils/AppContext";
import { Trans as T, useTranslation } from 'react-i18next';
import { IS_DEV } from "../../utils/envSettings";

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import PasswordInput from "../../common/PasswordInput/PasswordInput";

import './AuthForm.scss';

const DEV_USERS = [
  'andres@preciousleads.io',
  'yuan@preciousleads.io',
  'alejandro@preciousleads.io',
  'magda@preciousleads.io',
  'yiwen@preciousleads.io',
  'karen@preciousleads.io',
  'ricky@preciousleads.io',
  'raul@preciousleads.io',
];

export default function AuthForm({ type }) {

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [showDevUsers, setShowDevUsers] = useState(false);
  const [devUser, setDevUser] = useState(localStorage.getItem('pl__dev_default_user') || DEV_USERS[0]);
  const [errors, setErrors] = useState(null);
  
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(null);

  const [, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const isLogin = type === 'login';

  // NOTE -> this could be done inside the useState default, but since it only happens on DEV it's better to prevent:
  useEffect(() => {
    if (IS_DEV && isLogin && localStorage.getItem('pl__show_dev_users') !== 'false') {
      const user = localStorage.getItem('pl__dev_default_user') || DEV_USERS[0];

      setShowDevUsers(true);
      setDevUser(user);
      setEmail(user);
      setPassword('password');
    }
  }, [isLogin]);

  const handleRequestError = e => {
    // Server down:
    if (e?.code === 'ERR_NETWORK' || e?.response?.status === 500) {
      setErrors({ non_field_errors: ["The request could not be processed. Refresh this page or try again momentarily."] });
      setValidated(false); // do not show the form as valid if this happens
    }
    else if (e?.response?.data) {
      setErrors(e.response.data);
    }
    else {
      setErrors({ non_field_errors: ["The request could not be processed. Refresh this page or try again momentarily."] });
      setValidated(false); // do not show the ✔️ checks on form as valid if this happens
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setErrors(null);

    // this validation might be tricky, because it leads to the user to think that empty spaces (first_name.trim().length === 0) are valid
    // or emails such as me@there
    // const form = e.currentTarget;
    //
    // if (!form.checkValidity()) {
    //   setValidated(true);
    //   return;
    // }

    setLoading(true);

    isLogin ? await loginUser() : await registerUser();

    setLoading(false);
  }

  const registerUser = async () => {
    setValidated(false);

    try {
      const { data } = await api.post('/register/', { email, password, first_name: firstName, last_name: lastName });
      await dispatch({ type: 'accessToken/set', payload: data.token });
      
      const { data: userData } = await api.get('/users/me/', { headers: { Authorization: `Bearer ${data.token}` }});
      await dispatch({ type: 'currentUser/set', payload: userData });

      navigate('/');
    }
    catch (e) {
      setValidated(true);
      handleRequestError(e);
    }
  }

  const loginUser = async () => {
    try {
      const { data } = await api.post('/login/', {
        email,
        password
      });

      await dispatch({ type: 'accessToken/set', payload: data.token });
            
      const { data: userData } = await api.get('/users/me/', { headers: { Authorization: `Bearer ${data.token}` }});
      await dispatch({ type: 'currentUser/set', payload: userData });

      navigate('/');
    }
    catch (e) {
      handleRequestError(e);
    }
  }

  const toggleShowDevUsers = () => {
    if (!showDevUsers) {
      setEmail(devUser);
      setPassword('password');
    }

    setShowDevUsers(!showDevUsers);
    localStorage.setItem('pl__show_dev_users', !showDevUsers);
  }

  const selectDevUser = e => {
    localStorage.setItem('pl__dev_default_user', e.target.value);
    setDevUser(e.target.value);
    setEmail(e.target.value);
    setPassword('password');
  }

  return (
    <Form className='__auth-form' onSubmit={handleSubmit} noValidate validated={validated && !errors}>
      <h2><T>{ isLogin ? 'Log In' : 'Sign Up' }</T></h2>

      { IS_DEV && isLogin && (
        <>
          <div role='button' className="dev-accounts" onClick={toggleShowDevUsers}>{ showDevUsers ? 'Hide' : 'Show' } Dev Users</div>
          { showDevUsers && (
            <Form.Group className='language-select my-4'>
              <Form.Label>User</Form.Label>
              <Form.Select value={devUser} onChange={selectDevUser}>
                { DEV_USERS.map(user => (
                  <option key={user} disabled={user === devUser} value={user}>{ user }</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </>
      )}

      { !isLogin && (
        <div className="name-inputs">
          <Form.Group className="field">
            <Form.Control type="text" placeholder={t('First Name')} required value={firstName} onChange={e => setFirstName(e.target.value)} isValid={validated && !errors?.first_name} isInvalid={errors?.first_name} />
            { errors?.first_name?.map(error => <Form.Control.Feedback key={error} type="invalid"><T>{ error }</T></Form.Control.Feedback> )}
          </Form.Group>

          <Form.Group className="field">
            <Form.Control type="text" placeholder={t('Last Name')} required value={lastName} onChange={e => setLastName(e.target.value)} isValid={validated && !errors?.last_name} isInvalid={errors?.last_name} />
            { errors?.last_name?.map(error => <Form.Control.Feedback key={error} type="invalid"><T>{ error }</T></Form.Control.Feedback> )}
          </Form.Group>
        </div>
      )}

      <Form.Group className="field">
        <Form.Control type="email" placeholder={t('Email address')} required value={email} onChange={e => setEmail(e.target.value)} isValid={validated && !errors?.email} isInvalid={errors?.email} />
        { errors?.email?.map(error => <Form.Control.Feedback key={error} type="invalid"><T>{ error }</T></Form.Control.Feedback> )}
      </Form.Group>

      <Form.Group className="field">
        <PasswordInput placeholder={t('Password')} minLength={6} required value={password} onChange={e => setPassword(e.target.value)} isValid={validated && !errors?.password} isInvalid={errors?.password} feedback={ errors?.password?.map(error => <Form.Control.Feedback key={error} type="invalid"><T>{ error }</T></Form.Control.Feedback> )} />
      </Form.Group>

      <p><Link to='/reset'><T>Forgot your password?</T></Link></p>

      <div className="action">
        <button disabled={loading} type='submit'>
          { loading
          ?  <Spinner as="span" animation="border" size="sm" />
          : <T i18nKey={isLogin ? 'log-in-action' : 'sign-up-action' }/>
          }
        </button>
        <div className="request-error">{ errors?.non_field_errors?.map(error => <div key={error}>{ error }</div>) || '\u00A0' }</div>
      </div>

      <p className='create-account'>
        { isLogin
        ? <T i18nKey='create-free-account'>Don't have an account? <Link to='/signup'>Create a free acount</Link></T>
        : <T i18nKey='have-account-login'>Already have an account? <Link to='/login'>Log in</Link></T>
        }
      </p>
    </Form>
  )
}
