import { useState } from "react";
import i18n from "i18next";
import { Trans as T } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import AuthForm from "./AuthForm";

import './LoginSignup.scss';

export default function Signup() {

  const [language, setlanguage] = useState(() => i18n.language);

  const changeLanguage = e => {
    const lang = e.target.value;

    setlanguage(lang);
    localStorage.setItem('pl__language', lang);
    i18n.changeLanguage(lang);
  }

  return (
    <section className='__login-signup'>
      <h1>Precious Leads</h1>

      <div className="form-language">
        <AuthForm type='signup' />

        <Form.Group className='language-select'>
          <Form.Label><T>Language</T></Form.Label>
          <Form.Select value={language} onChange={changeLanguage}>
            <option value="en">English</option>
            <option value="es">Español</option>
          </Form.Select>
        </Form.Group>
      </div>
    </section>
  );
}
