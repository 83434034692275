import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import runes from "runes";
import AppContext from "../../utils/AppContext";
import useCategories from "../../hooks/useCategories";
import Dropdown from 'react-bootstrap/Dropdown';

import ChevronIcon from "../../assets/icons/ChevronIcon";
import ChartIcon from "../../assets/icons/ChartIcon";
import WrenchIcon from "../../assets/icons/WrenchIcon";
import ChevronThickIcon from "../../assets/icons/ChevronThickIcon";
import LeadsIcon from "../../assets/icons/LeadsIcon";
import UserIcon from "../../assets/icons/UserIcon";

import './Navigation.scss';
import useOrganizations from "../../hooks/useOrganizations";
import useBusinesses from "../../hooks/useBusinesses";
import PLink from "./PLink";
import PNavLink from "./PNavLink";
import BusinessDropdown from "./BusinessDropdown";

const NAV_LINKS = [
  { route: '/leads',     icon: LeadsIcon, label: "Leads" },
  { route: '/reporting', icon: ChartIcon, label: "Reports" },
  { route: '/users',     icon: UserIcon, label: "User Management", shortLabel: "Users" },
  { route: '/settings',  icon: WrenchIcon, label: "Business Settings", shortLabel: "Business" },
];

export default function Navigation(props) {

  const [state, dispatch] = useContext(AppContext);
  const { currentUser, localSettings } = state;

  const [searchParams, setSearchParams] = useSearchParams();

  const organizationId = parseInt(searchParams?.get('organization_id', 10)) || null
  const businessId = parseInt(searchParams?.get('business_id', 10)) || null;
  
  const match = useMatch('/leads/*');

  const [collapsed, setCollapsed] = useState(() => localSettings.navigation_collapsed);
  const { data: categories } = useCategories({ businessId, staleTime: 10000 });
  const { data: organizations } = useOrganizations({ staleTime: 10000 });
  const { data: businesses } = useBusinesses({ organization_id: organizationId, staleTime: 1000 });

  const organization = organizations?.find(organization => organization.id === organizationId);
  const business = businesses?.find(business => business.id === businessId);
  
  const navigationRef = useRef();

  // Check if the user has a default organization set. If not, set it:
  useEffect(() => {
    if (organizations && (!organizationId || !organizations.find(organization => organization.id === organizationId))) {
      setSearchParams(prev => (
        new URLSearchParams({ ...Object.fromEntries(prev.entries()), organization_id: organizations[0]?.id })
      ));
    }
  }, [organizations, organizationId, setSearchParams]);

  useEffect(() => {
    if (businesses && (!businessId || !businesses.find(business => business.id === businessId))) {
      setSearchParams(prev => (
        new URLSearchParams({ ...Object.fromEntries(prev.entries()), business_id: businesses[0]?.id })
      ));
    }
  }, [businesses, businessId, setSearchParams]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    dispatch({ type: 'localSettings/edit', payload: { setting: 'navigation_collapsed', value: !collapsed } });
  }

  const renderCategories = () => (
    <div className={classNames('main-route', { 'active-lead': match})} key='leads'>
      <PLink className={classNames('main-link', { active: match })} to='/leads'>
        <LeadsIcon className='link-icon' />
        <span>Leads</span>
      </PLink>
      { categories?.map(category => (
        <PNavLink key={category.id} className="sub-link" to={`/leads/${category.id}`}>{ category.name }</PNavLink>
      ))}
      { process.env.REACT_APP_SHOW_ALL_LEADS && <PNavLink className="sub-link view-all" to='/leads/'>All Leads</PNavLink> }
    </div>
  );

  const renderCategoriesMenu = () => (
    <Dropdown drop='end' key='leads-dropdown' className='leads-dropdown'>
      <Dropdown.Toggle as={CategoriesToggle} active={match}>
        <LeadsIcon className='link-icon' />
        <span>Leads</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='categories-title'>Categories</div>
        { categories?.map(category => (
          <Dropdown.Item as={PNavLink} key={category.id} to={`/leads/${category.id}`}>{ category.name }</Dropdown.Item>
        ))}
        { process.env.REACT_APP_SHOW_ALL_LEADS && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item as={PNavLink} className="view-all" to='/leads/'>View All Leads</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  const userInitials = runes.substr(currentUser.first_name, 0, 1) + runes.substr(currentUser.last_name, 0, 1);

  const renderIcon = icon => {
    const Icon = icon;
    return <Icon className='link-icon' />;
  }

  return (
    <CSSTransition classNames='collapsed' nodeRef={navigationRef} appear in={collapsed} timeout={500}>
      <section className={classNames('__navigation', { collapsed })} ref={navigationRef}>
        <button className='toggle-navigation' onClick={toggleCollapsed}><ChevronThickIcon /></button>
        
        <div className='inner-container'>
          <div className='overlay-gradient' />
          <h1><PLink to='/'>Precious Leads</PLink></h1>

          <BusinessDropdown organizationId={organizationId} businesses={businesses} selected={business} />

          <nav>
            { NAV_LINKS.map(link => (
              link.route === '/leads'
              ? (collapsed ? renderCategoriesMenu() : renderCategories())
              : <PNavLink className="main-route" key={link.route} to={link.route}>
                  { renderIcon(link.icon) }
                  <span>{ collapsed ? (link.shortLabel || link.label) : link.label }</span>
                </PNavLink>
            ))}
          </nav>

          <PLink className="user-settings" to='/account'>
            <div className="profile-picture">{ userInitials }</div>
            <div className="user-name-account">
              <div className="user-name">{ currentUser.first_name } { currentUser.last_name }</div>
              { organizations?.length > 1 && <div className="user-account">{ organization?.name }</div> }
            </div>
            <ChevronIcon className='expand' />
          </PLink>
        </div>
        
        <div className='toggle-area' />
      </section>
    </CSSTransition>
  );
}

const CategoriesToggle = forwardRef(({ children, onClick, active, ...props }, ref) => (
  <div role='button' onClick={onClick} ref={ref} {...props} className={classNames('main-route', { active })}>
    { children }
  </div>
));
