import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import classNames from 'classnames';
import useMutateLead from '../../hooks/useMutateLead';
import useStatuses from '../../hooks/useStatuses';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { Spinner } from 'react-bootstrap';

import './LeadDetailsModal.scss';

const FIELDS_MAIN = [
  'full_name',
  'first_name',
  'last_name',
  'email',
  'phone',
  'status',
  'gender',
  'birthdate',
];

const FIELDS_ADDRESS = [
  'country',
  'city',
  'state',
  'zip_code',
  'street_address',
];

export default function LeadDetailsModal({ show, onHide, lead, categoryId }) {
  const [editedLead, setEditedLead] = useImmer({});

  const { updateMutation, insertMutation } = useMutateLead();
  const { data: statuses } = useStatuses({ categoryId });

  useEffect(() => {
    if (show) {
      setEditedLead(lead || {});
    }
  }, [show, setEditedLead, lead]);

  const onSubmit = () => {
    const data = {};

    [...FIELDS_MAIN, ...FIELDS_ADDRESS].forEach(field => {
      if (lead?.[field] !== editedLead[field]) {
        data[field] = editedLead[field];
      }
    });
    
    
    if (!data.status) {
      data.status = statuses.find(status => status.code === 'new').id;
    }
    
    if (!lead) {
      data.category = 1;
      insertMutation.mutate({ data }, { onSuccess: onHide });
    }
    else {
      Object.entries(data).length > 0 && updateMutation.mutate({ id: lead.id, data }, { onSuccess: onHide });
    }
  }

  const renderStatus = () => {
    const status = statuses.find(status => status.id === editedLead.status_id) || statuses.find(status => status.code === 'new');

    return status && (
      <Dropdown className='status-dropdown'>
        {/* <Dropdown.Toggle as={StatusToggle} className={classNames('status-toggle', `color-${status.code}`)}> */}
        <Dropdown.Toggle className={classNames('status-toggle', `color-${status.code}`)}>
          { status.name }
          {/* <div className="toggle-overflow">
            <div className='label'>{ status?.label }</div>
            <ChevronIcon className='chevron' />
          </div> */}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          { statuses.map(statusOptions => (
            <Dropdown.Item key={statusOptions.id} as='button' onClick={() => setEditedLead(draft => { draft.status_id = statusOptions.id })} className={`color-${statusOptions.code}`}>
              { statusOptions.label }
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const renderField = field => (
    <div key={field} className='field'>
      <label>{ field.replace('_', ' ') }</label>
      { field === 'status'
      ? statuses && renderStatus()
      : <Form.Control type='text' value={editedLead[field] || ''} onChange={e => setEditedLead(draft => { draft[field] = e.target.value })} />
      }
    </div>
  );

  const loading = insertMutation.isLoading || updateMutation.isLoading;

  return (
    <Modal className='__lead-details-modal' show={show} onHide={onHide} centered size='lg'>
      <Modal.Header onHide={onHide} closeButton>Lead Details</Modal.Header>
      <Modal.Body>
        <div className='field-columns'>
          <div>{ FIELDS_MAIN.map(field => renderField(field)) }</div>
          <div>{ FIELDS_ADDRESS.map(field => renderField(field)) }</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button onClick={onSubmit} disabled={loading}>
          { loading
          ? <><Spinner size='sm' />&nbsp; Saving</>
          : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
