import { getExactTranslations } from "./translations_en_exact";

// This file contains longer strings or strings that require interpolation.
// For short strings, the file `translations_en_exact.js` is used.
const TRANSLATIONS_EN = {
  // Auth
  'log-in-action'         : "Log In",
  'sign-up-action'        : "Sign Up",
  'have-account-login'    : "Already have an account? <1>Log in</1>",
  'create-free-account'   : "Don't have an account? <1>Create a free acount</1>",
  'password-too-short'    : "Your password should have a minimum of 6 characters",

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Translations that are 1:1 in English (default Development language):
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  ...getExactTranslations(),
}

export default TRANSLATIONS_EN;
