import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';

import './ConfirmationModal.scss';

export default function ConfirmationModal({ title, children, cancelText, confirmText, onHide, onConfirm, ...props }) {
  return (
    <Modal className="__confirmation-modal __modal" onHide={onHide} {...props} backdrop='static' centered>
      <Modal.Header onHide={onHide} closeButton>
        <h2>Delete Lead Status</h2>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this status?</p>
        <p>Any lead assigned to this status will be reset to the default status.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='cancel' onClick={onHide}>{ cancelText || 'Cancel' }</Button>
        <Button className='confirm' onClick={onConfirm}>{ confirmText }</Button>
      </Modal.Footer>
    </Modal>  
  );
}
