import { useState } from 'react';
import classNames from 'classnames';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import { ReactComponent as EyeOnIcon } from "../../assets/icons_svg/eye-on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons_svg/eye-off.svg";

import './PasswordInput.scss';

export default function PasswordInput({ className, feedback, ...props }) {
  const [revealed, setRevealed] = useState(false);

  const toggleReveal = e => {
    setRevealed(!revealed);
  }

  return (
    <>
      <InputGroup className={classNames('__password-input', className)}>
        <Form.Control type={revealed ? 'text' : 'password'} {...props} />
        <button type='button' className='reveal-button' onClick={toggleReveal} tabIndex={-1}>
          { revealed ? <EyeOffIcon /> : <EyeOnIcon /> }
        </button>
        { feedback }
      </InputGroup>
    </>
  )
}
