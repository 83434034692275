import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import SortableStatus from "./SortableStatus";
import { arrayMove } from "@dnd-kit/sortable";
import classNames from "classnames";
import { useDebouncedCallback } from "use-debounce";

import { handleNetworkError } from "../../utils/api";
import useStatuses from "../../hooks/useStatuses";
import useMutateStatus from "../../hooks/useMutateStatus";
import LEAD_STATUS_COLORS from "../../utils/leadStatusColors";

import Form from 'react-bootstrap/Form';
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import Button from "../../common/Button/Button";
import StatusColorPicker from "./StatusColorPicker";
import EditableStatusField from "./EditableStatusField";
import TrashIcon from "../../assets/icons/TrashIcon";

import './LeadStatusManager.scss';

export default function LeadStatusManager({ categoryId, showSavedToast }) {
  const [statusOrder, setStatusOrder] = useImmer(null);
  const [showCreate, setShowCreate] = useState(false);
  const [createName, setCreateName] = useState('');
  const [createDescription, setCreateDescription] = useState(undefined);
  const [createColor, setCreateColor] = useState(false);
  const [creating, setCreating] = useState(false);
  const [createErrors, setCreateErrors] = useState(false);
  const [createValidated, setCreateValidated] = useState(false);
  const [createdStatusId, setCreatedStatusId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: statuses, isLoading } = useStatuses({ categoryId });
  const { updateStatus, bulkUpdateStatus, createStatus, deleteStatus } = useMutateStatus();

  const addRef = useRef();
  const newItemRef = useRef();

  useEffect(() => {
    const order = statuses?.sort((a, b) => a.order_index - b.order_index).map(status => ({ id: status.id }));
    order && setStatusOrder(order);
  }, [statuses, setStatusOrder]);

  useLayoutEffect(() => {
    if (showCreate) {
      const rect = addRef.current.getBoundingClientRect();

      if (rect.bottom > window.innerHeight || rect.top < 0) {
        addRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [showCreate]);

  useLayoutEffect(() => {
    if (createdStatusId) {
      newItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [createdStatusId]);

  const debouncedRemoveCreatedStatus = useDebouncedCallback(() => {
    setCreatedStatusId(null);
  }, 2000);

  if (isLoading || !statusOrder) {
    return null;
  }

  const onCreateStatus = e => {
    e.preventDefault();

    setCreating(true);
    setCreateErrors(false);
    setCreateValidated(false);

    const lastIndex = statuses.find(status => status.id === statusOrder[statusOrder.length - 1]?.id)?.order_index || 0;

    const data = {
      category_id : categoryId,
      name        : createName,
      description : createDescription || undefined,
      color       : createColor,
      order_index : lastIndex + 1,
    }

    const onSettled = () => {
      setCreating(false);
    }

    const onSuccess = newStatus => {
      setShowCreate(false);
      setCreatedStatusId(newStatus.id);
      debouncedRemoveCreatedStatus();
    }

    const onError = e => {
      handleNetworkError({ e, setErrors: setCreateErrors, setValidated: setCreateValidated });
    }

    createStatus.mutate({ data }, { onSettled, onSuccess, onError });
  }

  const onDelete = id => {
    setDeleteId(id);
    setShowDelete(true);
  }

  const confirmDelete = () => {
    deleteStatus.mutate(deleteId, { onSuccess: () => showSavedToast('Lead status deleted') });
    setShowDelete(false);
  }

  const onChangeColor = (id, color) => {
    updateStatus.mutate({ data: { color }, id }, { onSuccess: () => showSavedToast() });
  }

  const onChangeOrder = (activeIndex, overIndex) => {
    const lowestIndex = Math.min(activeIndex, overIndex);
    const highestIndex = Math.max(activeIndex, overIndex);

    const newOrder = arrayMove(statusOrder, activeIndex, overIndex);

    const data = [];

    for (let i = lowestIndex; i <= highestIndex; ++i) {
      const item = statuses.find(status => status.id === newOrder[i].id);
      data.push({ id: item.id, order_index: i });
    }

    bulkUpdateStatus.mutate({ data, categoryId });
    setStatusOrder(newOrder);
  }

  const toggleAdd = () => {
    setShowCreate(!showCreate);
    
    if (!showCreate) {
      const colorValues = Object.values(LEAD_STATUS_COLORS).map(color => color.toLowerCase());
      const statusColors = statuses.map(status => status.color.toLowerCase());
      const unusedColor = colorValues.find(color => !statusColors.includes(color));
      
      setCreateName('');
      setCreateDescription(undefined);
      setCreateColor(unusedColor || colorValues[0]);
      setCreateErrors(null);
      setCreateValidated(false);
    }
  }

  const onEditSucces = () => {
    showSavedToast('Lead status updated')
  }

  const renderItem = item => {
    const status = statuses.find(status => item.id === status.id);

    // when deleting a status, the data might be insconsistent, delete if no status found
    if (!status) return;

    const newlyCreated = createdStatusId === status.id;

    return (
      <SortableStatus.Item id={status.id} newlyCreated={newlyCreated}>
        <div className="row-handle" ref={newItemRef}><SortableStatus.DragHandle /></div>
        <div className="row-name"><EditableStatusField field='name' initialValue={status.name} id={status.id} onEditSucces={onEditSucces} /></div>
        <div className="row-color">
          <StatusColorPicker color={status.color} onChange={color => onChangeColor(status.id, color)} />
        </div>
        <div className="row-description">
          <EditableStatusField field='description' initialValue={status.description} id={status.id} onEditSucces={onEditSucces} />
        </div>
        <div className="row-delete">
          { status.can_be_deleted && <button className='delete-btn' onClick={() => onDelete(status.id)}><TrashIcon /> Remove</button> }
        </div>
      </SortableStatus.Item>
    );
  }

  return (
    <div className="__lead-status-manager">
      <div className="title-actions">
        <h2>Lead Status</h2>
        { !showCreate && <button className="main-action" onClick={toggleAdd}>＋ Add Status</button> }
      </div>

      { showCreate && (
        <div className="add-status" ref={addRef}>
          <Form onSubmit={onCreateStatus} noValidate validated={createValidated && !createErrors}>
            <div className="name-description">
              <Form.Group className="name">
                <Form.Label>Name</Form.Label>
                <Form.Control autoFocus value={createName} onChange={e => setCreateName(e.target.value)} isValid={createValidated && !createErrors?.name} isInvalid={createErrors?.name} />
                { createErrors?.name?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
              </Form.Group>

              <Form.Group className="description">
                <Form.Label>Description</Form.Label>
                <Form.Control placeholder="(optional)" value={createDescription || ''} onChange={e => setCreateDescription(e.target.value)} isValid={createValidated && !createErrors?.description} isInvalid={createErrors?.description} />
                { createErrors?.description?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
              </Form.Group>
            </div>
            <div className="color-actions">
              <div className="colors-container">
                <div className="label">Color</div>
                { Object.values(LEAD_STATUS_COLORS).map(color => (
                  <button key={color} type='button' onClick={() => setCreateColor(color.toLowerCase())} className={classNames("color", { selected: color.toLowerCase() === createColor.toLowerCase() })} style={{ backgroundColor: `#${color}` }} />
                ))}
              </div>
              <div className="actions">
                <Button disabled={creating} className='cancel' type='button' onClick={() => setShowCreate(false)}>Cancel</Button>
                <Button loading={creating} type='submit'>Create</Button>
              </div>
            </div>

            { createErrors?.non_field_errors?.map(error => <div className="request-error" key={error}>{ error }</div> )}
          </Form>
        </div>
      )}

      <div className="leads-table">
        <div className="table-head">
          <div className="row-handle" />
          <div className="row-name">Name</div>
          <div className="row-color">Color</div>
          <div className="row-description">Description</div>
          <div className="row-delete" />
        </div>
        <SortableStatus items={statusOrder} onChange={onChangeOrder} renderItem={renderItem} />
      </div>

      <ConfirmationModal show={showDelete} title='Delete Lead Status' confirmText='Delete' onHide={() => setShowDelete(false)} onConfirm={confirmDelete}>
        <p>Are you sure you want to delete this status?</p>
        <p>Any lead assigned to this status will have its status reset to the default category.</p>
      </ConfirmationModal>
    </div>
  );
}
