import { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useImmer } from 'use-immer';
import LEAD_FIELDS from '../../utils/leadFields';

import './FilterDropdown.scss';
import classNames from 'classnames';

// NO NAME FOR NOW
// const TEXT_FIELDS = ['full_name', 'email', 'country', 'state', 'city', 'zip_code'];
const TEXT_FIELDS = ['email', 'country', 'state', 'city', 'zip_code'];

export default function FilterDropdown({ toggle, children, filters, index, setFilters, align }) {
  const [show, setShow] = useState(false);
  const [editingFilter, setEditingFilter] = useImmer({});
  const onToggleShow = () => setShow(!show);

  const defaultInputRef = useRef();

  useEffect(() => {
    if (show) {
      if (typeof index === 'number') {
        setEditingFilter(filters[index]);
      }
      else {
        setEditingFilter({
          field: TEXT_FIELDS.find(field => !filters.find(filter => filter.field === field)), 
          match: 'contains',
        });
      }

      defaultInputRef.current?.focus();
    }
    else {
      setEditingFilter({});
    }
  }, [show, filters, index, setEditingFilter]);

  const onSubmit = e => {
    e.preventDefault();

    setFilters(draft => {
      if (typeof index !== 'number') {
        draft.push(editingFilter);
      }
      else {
        draft[index] = editingFilter;
      }
    });

    setShow(false);
  }

  return (
    <Dropdown show={show} onToggle={onToggleShow} className='__filter-dropdown' align={align}>
      <Dropdown.Toggle as={toggle}>{ children }</Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='transition-container'>
          <Form onSubmit={onSubmit}>
            <div className='field-match'>
              <Form.Select className='field' value={editingFilter.field} onChange={e => setEditingFilter(draft => { draft.field = e.target.value })}>
                { TEXT_FIELDS.map(field => (
                  <option key={field} value={field} disabled={filters.some(f => f.field === field) && editingFilter.field !== field}>
                    { LEAD_FIELDS[field] }
                  </option>
                ))}
              </Form.Select>

              <Form.Select className='match' value={editingFilter.match} onChange={e => setEditingFilter(draft => { draft.match = e.target.value })}>
                { ['contains', 'is'].map(match => (
                  <option key={match} value={match}>{ match }</option>
                ))}
              </Form.Select>
            </div>

            <Form.Control minLength={3} className='input-value' type='text' value={editingFilter.value || ''} placeholder='Enter filter value...' onChange={e => setEditingFilter(draft => { draft.value = e.target.value })} ref={defaultInputRef} />

            { editingFilter.value?.trim().length > 0 && (
              <p className={classNames('explanation', { visible: editingFilter.value?.trim().length > 0 })}>
                Display all leads whose {LEAD_FIELDS[editingFilter.field]} {editingFilter.match === 'is' ? 'exactly matches' : 'contains the text'}: <span className='value'>{editingFilter.value.trim()}</span>
              </p>
            )}

            <div className='actions'>
              <button type='button' className='close' onClick={onToggleShow}>Close</button>
              <button type='submit' disabled={!editingFilter.value?.trim().length}>{ typeof index === 'number' ? 'Edit' : 'Add' } Filter</button>
            </div>
          </Form>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
