import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { handleNetworkError } from "../../utils/api";
import useMutateCategory from "../../hooks/useMutateCategory";
import useCategory from "../../hooks/useCategory";

import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import LeadsMode from "../../common/Navigation/LeadsMode";
import LeadStatusManager from "./LeadStatusManager";
import Toast from "../../common/Toast/Toast";
import Button from "../../common/Button/Button";
import StatusDropdown from "../Leads/StatusDropdown";
import LeadDistribution from "./LeadDistribution";

import { ReactComponent as InfoIcon } from '../../assets/icons_svg/info.svg';

import './CategorySettings.scss';

const DEFAULT_SAVED_MESSAGE = 'Changes saved';

export default function CategorySettings(props) {

  const { categoryId: paramCategoryId } = useParams();
  const categoryId = parseInt(paramCategoryId, 10);
  
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(DEFAULT_SAVED_MESSAGE);

  const [categoryName, setCategoryName] = useState(null);
  const [savingName, setSavingName] = useState(false);
  const [nameErrors, setNameErrors] = useState(null);
  const [nameValidated, setNameValidated] = useState(false);

  const [urlCopied, setUrlCopied] = useState(false);

  const [searchParams] = useSearchParams();

  // const organizationId = parseInt(searchParams?.get('organization_id', 10)) || null
  const businessId = parseInt(searchParams?.get('business_id', 10)) || null;
  
  const { data: category, isLoading } = useCategory({ businessId, categoryId });
  const { updateCategory } = useMutateCategory();

  useEffect(() => {
    if (category && categoryName === null) {
      setCategoryName(category.name)
    }
  }, [category, categoryName]);

  const renderLoading = () => <div>Loading...</div>

  const showSavedToast = (message = DEFAULT_SAVED_MESSAGE) => {
    hideSavedToast.cancel();
    setShowToast(true);
    setToastMessage(message);
    hideSavedToast();
  }

  const hideSavedToast = useDebouncedCallback(() => {
    setShowToast(false);
  }, [2000]);

  const handleRename = async e => {
    e.preventDefault();

    // if the name didn't change, don't do anything
    if (categoryName?.trim() === category.name) {
      return;
    }

    setSavingName(true);
    setNameErrors(null);
    setNameValidated(false);

    const onError = e => {
      handleNetworkError({ e, setErrors: setNameErrors, setValidated: setNameValidated });
    }

    const onSuccess = () => {
      showSavedToast();
      document.activeElement.blur();
    }

    const onSettled = () => {
      setSavingName(false);
    }

    updateCategory.mutate({ data: { name: categoryName }, id: categoryId }, { onError, onSuccess, onSettled });
  }

  const onChangeDefaultStatus = async id => {
    updateCategory.mutate({ data: { default_status_id: id }, id: categoryId });
  }

  const handleCopyWebhookURL = async e => {
    e.preventDefault();

    await navigator.clipboard.writeText(category.webhook.url);

    setUrlCopied(true);
    debounceHideUrlCopied();
  }

  const debounceHideUrlCopied = useDebouncedCallback(() => {
    setUrlCopied(false);
  }, 2000);

  const renderStatusTooltip = props => (
    <Tooltip {...props} className="__default-status-tooltip">
      All incoming leads in this category will
      have this status assigned by default.
    </Tooltip>
  )

  const renderContent = () => (
    <div className="content">
      <h2>General Settings</h2>
      <div className="section">
        <Form noValidate validated={nameValidated && !nameErrors} className="single-field" onSubmit={handleRename}>
          <Form.Label>Category name</Form.Label>
          <Form.Group className="input-button">
            <Form.Control value={categoryName || ''} onChange={e => setCategoryName(e.target.value)} isValid={nameValidated && !nameErrors?.name} isInvalid={nameErrors?.name} />
            <Button type='submit' loading={savingName} disabled={categoryName?.trim().length === 0}>Rename</Button>
            { nameErrors?.name?.map(error => <Form.Control.Feedback key={error} type="invalid">{ error }</Form.Control.Feedback> )}
            { nameErrors?.non_field_errors?.map(error => <div className="errors" key={error}>{ error }</div> )}
          </Form.Group>

        </Form>
        <div className="default-status-info">
          <div className="default-status">
            <div className="label">Default Status</div>
            <StatusDropdown categoryId={categoryId} selectedId={category.default_status_id} onChange={onChangeDefaultStatus} />
          </div>
          <OverlayTrigger placement="right" delay={{ show: 200, hide: 400 }} overlay={renderStatusTooltip}>
            <InfoIcon className="info-icon" />
          </OverlayTrigger>
        </div>
      </div>

      <h2>Integrations</h2>
      <div className="section">
        <Form className="single-field" onSubmit={handleCopyWebhookURL}>
          <div className="label-url-copied">
            <Form.Label>Webhook URL</Form.Label>
            { urlCopied && <div className="url-copied">URL copied to clipboard</div> }
          </div>
          <div className="input-button">
            <Form.Control value={category.webhook.url} readOnly />
            <button type='submit'>{ urlCopied ? 'Copied' : 'Copy URL' }</button>
          </div>
          <div className="field-description">
            Precious Leads can be integrated with most platforms such as Facebook Ads and Zapier using <strong>webhooks</strong>. Visit our <a href='#GUIDE'>Integration Guide</a> for more details.
          </div>
        </Form>
      </div>

      <LeadStatusManager categoryId={categoryId} showSavedToast={showSavedToast} />

      <LeadDistribution />

      <Toast show={showToast} hideClose>{ toastMessage }</Toast>
    </div>
  )

  return (
    <section className='__category-settings'>
      <LeadsMode />
      { isLoading ? renderLoading() : renderContent() }
    </section>
  );
}
