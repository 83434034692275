import { useContext, useEffect } from "react";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
import AppContext from "../../utils/AppContext";

export default function Logout(props) {

  const navigate = useNavigate();
  const [, dispatch] = useContext(AppContext);

  const queryFn = () => api.delete('/logout/');
  const { status } = useQuery({ queryKey: ['logout'], queryFn });

  useEffect(() => {
    // force deletion of cookies even if the status fails
    if (status !== 'loading') {
      dispatch({ type: 'currentUser/delete' });
      const queryClient = new QueryClient();
      queryClient.removeQueries();

      navigate('/');
    }
  }, [status, dispatch, navigate]);

  if (status === 'loading') {
    return <div>Logging out...</div>
  }
}
