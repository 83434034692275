export default function TrashIcon(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 32 32" {...props}>
      <g>
        <path d="M3 7h2v20.48A3.53 3.53 0 0 0 8.52 31h15A3.53 3.53 0 0 0 27 27.48V7h2a1 1 0 0 0 0-2h-7V3a2 2 0 0 0-1.95-2H12a2 2 0 0 0-2 2v2H3a1 1 0 0 0 0 2zm9-4h8v2h-8zm-2 4h15v20.48A1.52 1.52 0 0 1 23.48 29h-15A1.52 1.52 0 0 1 7 27.48V7z" />
        <path d="M12.68 25a1 1 0 0 0 1-1V12a1 1 0 0 0-2 0v12a1 1 0 0 0 1 1zM19.32 25a1 1 0 0 0 1-1V12a1 1 0 0 0-2 0v12a1 1 0 0 0 1 1z" />
      </g>
    </svg>
  );
}
