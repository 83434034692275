import { useParams } from "react-router-dom";
// import useCategories from "../../hooks/useCategories";
import useLeads from "../../hooks/useLeads";
import { MultipleContainers } from "../Board/MultipleContainers";
import { useMemo } from "react";
import useStatuses from "../../hooks/useStatuses";

export default function LeadsKanban() {

  const { categoryId: paramCategoryId } = useParams();
  const categoryId = parseInt(paramCategoryId, 10);

  const { data: leads, isLoading, /*isFetching, isError, error*/ } = useLeads({ staleTime: 5000, categoryId, keepPreviousData: true });
  // const { data: categories } = useCategories({ staleTime: 10000, keepPreviousData: true });
  const { data: statuses } = useStatuses({ categoryId });

  console.log('categoryId:', categoryId)

  const kanbanItems = useMemo(() => {
    console.log('statuses:', statuses)

    if (!statuses || !leads || statuses.length === 0) {
      return null;
    }

    const items = {};

    statuses?.forEach(status => {
      if (status.label === 'Discarded') return;
      items[status.label] = [];
    });

    leads?.forEach(lead => {
      console.log('statuses:', statuses)
      console.log('lead:', lead)
      const statusLabel = statuses.find(status => status.id === lead.status_id).label;
      if (statusLabel === 'Discarded') return;
      items[statusLabel].push(lead.id);
    });
    
    return items;
  }, [leads, statuses]);

  const renderContent = () => {
    if (isLoading || kanbanItems === null) {
      return <pre>Loading...</pre>
    }

    return <MultipleContainers key={categoryId} leads={leads} items={kanbanItems} />
  }

  return (
    <section className='__leads-kanban'>
      { renderContent() }
    </section>
  )
}
