import { useState } from "react"
import { api } from "../utils/api";
import { Button } from "react-bootstrap";

export default function Health(props) {
  const [health, setHealth] = useState(null);
  const [responseTime, setResponseTime] = useState(null);

  const checkHealth = async () => {
    setHealth(null);
    setResponseTime(null);

    const startTime = new Date();

    try {
      await api.get('/health/');
      const endTime = new Date();

      setResponseTime(endTime.getTime() - startTime.getTime());
      setHealth('OKAY');
    }
    catch (e) {
      console.log('error:', e);
      setHealth('UNREACHABLE');
    }
  }

  return (
    <div className="m-4 text-center">
      <Button onClick={checkHealth}>Check Health</Button>
      <pre className="my-2">
        { health !== null && <div>Status.......... <strong>{health}</strong></div> }
        { health === 'OKAY' && <div>Response time... {responseTime}ms</div> }
      </pre>
    </div>
  )
}
