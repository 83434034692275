import { forwardRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import LEAD_STATUS_COLORS from '../../utils/leadStatusColors';
import './StatusColorPicker.scss';

export default function StatusColorPicker({ color, onChange }) {
  const filteredColors = Object.values(LEAD_STATUS_COLORS).filter(itemColor => itemColor.toUpperCase() !== color.toUpperCase());

  return (
    <Dropdown className='__status-color-picker'>
      <Dropdown.Toggle as={ColorToggle}>
        <div className='color' style={{ backgroundColor: `#${color}` }} />
      </Dropdown.Toggle>
      <Dropdown.Menu flip={false}>
        <div className='transition-container'>
          <Dropdown.Item as='button' key={color}><div className='color' style={{ backgroundColor: `#${color}` }} /></Dropdown.Item>
          <Dropdown.Divider />
          { filteredColors.map(color => (
            <Dropdown.Item as='button' key={color} onClick={() => onChange(color)}><div className='color' style={{ backgroundColor: `#${color}` }} /></Dropdown.Item> )
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const ColorToggle = forwardRef(({ children, onClick, active, ...props }, ref) => (
  <div role='button' onClick={onClick} ref={ref} {...props} className='toggle'>
    { children }
  </div>
));

