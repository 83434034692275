import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getBusinesses = ({ queryKey }) => {
  const [, organization_id] = queryKey;

  if (!organization_id) {
    return null;
  }

  return api.get(`/businesses/?organization_id=${organization_id}`).then(res => res.data);
}

export default function useBusinesses({ organization_id, ...props }) {
  return useQuery({ queryKey: ['businesses', organization_id], queryFn: getBusinesses, ...props });
}
