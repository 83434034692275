import Spinner from 'react-bootstrap/Spinner';

import './Button.scss';

export default function Button({ loading, disabled, as = 'button', children, ...props }) {
  const Component = as;

  return (
    <Component disabled={disabled || loading} {...props}>
      { loading ? <Spinner as="span" animation="border" size="sm" /> : children }
    </Component>
  );
}
