const LEAD_STATUS_COLORS = {
  red        : 'FE0707',
  orange     : 'EC7340',
  yellow     : 'FFCE21',
  green      : '5fbe5f',
  teal       : '4E9DB3',
  turquoise  : '03DFDF',
  cadbury    : '515FE5',
  purple     : '9D33E1',
  pink       : 'EB08AB',
  brown      : '9A3E0A',
  light_gray : 'B4B4B4',
  dark_gray  : '535353',
}

export default LEAD_STATUS_COLORS;
