import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getUserRoles = ({ queryKey }) => {
  const [, organizationId] = queryKey;

  if (!organizationId) {
    console.warn('useUsers: missing organizationId');
    return;
  }

  return api.get(`/user-roles/?organization_id=${organizationId}`).then(res => res.data);
}

export default function useUserRoles({ organizationId, ...props }) {
  return useQuery({ queryKey: ['user-roles', organizationId], queryFn: getUserRoles, ...props });
}
