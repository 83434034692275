import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getUsers = ({ queryKey }) => {
  const [, organizationId] = queryKey;

  if (!organizationId) {
    console.warn('useUsers: missing organizationId');
    return;
  }

  return api.get(`/users/?organization_id=${organizationId}`).then(res => res.data);
}

export default function useUsers({ organizationId, ...props }) {
  return useQuery({ queryKey: ['users', organizationId], queryFn: getUsers, ...props });
}
