const LEAD_FIELDS = {
  full_name   : 'Name',
  email       : 'Email',
  phone       : 'Phone',
  status      : 'Status',
  assignee    : 'Assigned To',
  date_added  : 'Date Added',
  country     : 'Country',
  state       : 'State',
  city        : 'City',
  zip_code    : 'Zip Code',
}

export default LEAD_FIELDS;
